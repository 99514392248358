window.addEventListener("DOMContentLoaded", initApp);
function HTMLTabs(inputProps){
	var props = {
		buttonsSelector: ".js-tab-button",
		contentSelector: ".js-tab-content",
		buttonActiveClass: "tab-button--active",
		contentActiveClass: "tab-content--active",
		initialTab: 0,
		scrollToView:true,
		dataAttributeIds: false,
		onBeforeTabSwitch: null,
		onAfterTabSwitch: null,
		firstScroll: false,
	};
	for(var prop in inputProps){
		if(props.hasOwnProperty(prop)){
			props[prop] = inputProps[prop];
		}
	}
	this.buttonNodes = document.querySelectorAll(props.buttonsSelector);
	this.contentNodes = document.querySelectorAll(props.contentSelector);
	if(this.buttonNodes.length && this.contentNodes.length){
		this.buttonActiveClass = props.buttonActiveClass;
		this.contentActiveClass = props.contentActiveClass;
		this.dataAttributeIds = props.dataAttributeIds;
		this.initialTab = props.initialTab;
		this.scrollToView = props.scrollToView;
		this.onBeforeTabSwitch = props.onBeforeTabSwitch && typeof props.onBeforeTabSwitch === "function" ? props.onBeforeTabSwitch : null;
		this.onAfterTabSwitch = props.onAfterTabSwitch && typeof props.onAfterTabSwitch === "function" ? props.onAfterTabSwitch : null;
		if(this.dataAttributeIds && !this.initialTab){
			this.initialTab = this.buttonNodes[0].dataset.tabId;
		}
		this.buttonClones = [];
		this.currentTab = null;
		this.tabs = [];
		this.init();
		this.switchTab(this.initialTab);
	}
}
HTMLTabs.prototype.init = function(){
	for(var i = 0; i < this.buttonNodes.length; i++){
		var tabId = i;
		if(this.dataAttributeIds){
			tabId = this.buttonNodes[i].dataset.tabId;
			this.tabs[tabId] = {};
			this.tabs[tabId].buttonNode = this.buttonNodes[i];

			for(var j = 0; j < this.contentNodes.length; j++){
				if(this.contentNodes[j].dataset.tabId === tabId){
					this.tabs[tabId].contenNode = this.contentNodes[j];
					break;
				}
			}

			var buttonClone = document.querySelector("[data-tab-clone][" + "data-tab-id=" + tabId + "]" );
			if(buttonClone){
				this.buttonClones[tabId] = buttonClone;
			}
		}else{
			this.tabs[tabId] = {};
			this.tabs[tabId].buttonNode = this.buttonNodes[i];
			this.tabs[tabId].contenNode = this.contentNodes[i];
		}
		(function (tabId, instance) {
			instance.tabs[tabId].buttonNode.addEventListener("click", function(){
				var shouldTabBeSwitched = true;
				if(instance.onBeforeTabSwitch){
					shouldTabBeSwitched = instance.onBeforeTabSwitch(tabId) === false ? false : true;
				}
				if(shouldTabBeSwitched){
					instance.switchTab(tabId);
				}
			});
		})(tabId, this); 
	}
};
HTMLTabs.prototype.switchTab = function(tabId){
	if(this.tabs[tabId] && this.currentTab !== tabId){
		if(this.currentTab !== null){
			this.tabs[this.currentTab].buttonNode.classList.remove(this.buttonActiveClass);
			this.tabs[this.currentTab].contenNode.classList.remove(this.contentActiveClass);
			if(this.buttonClones && this.buttonClones.length){
				this.buttonClones[this.currentTab].classList.remove(this.buttonActiveClass);
			}
		}

		this.tabs[tabId].buttonNode.classList.add(this.buttonActiveClass);
		this.tabs[tabId].contenNode.classList.add(this.contentActiveClass);
		if(this.buttonClones && this.buttonClones.length){
			this.buttonClones[tabId].classList.add(this.buttonActiveClass);
		}

		if (this.scrollToView && this.firstScrolled) {
			if (this.tabs[tabId].contenNode.offsetTop < window.pageYOffset || this.tabs[tabId].contenNode.offsetTop > window.pageYOffset + window.innerHeight) {
				window.scrollTo(0, this.tabs[tabId].contenNode.offsetTop);
			}
		}

		this.firstScrolled = true;
		this.currentTab = tabId;
        if(this.onAfterTabSwitch){
            this.onAfterTabSwitch(tabId);
        }
	}
};
function initApp() {
    initHeaderMenu();
    initReviews();
    initStagesSlider();
    initModals();
    initVideoReviews();
    initMasks();
    initUSA();
    initEquipmentTabs();
    initCalc();
}
function initCalc(){
    initSingleSlider("#calc_area", 35, 200);
    $("#calc_area_walls").change(function(){
        if(this.checked){
            $("#calc_area").slider("option", "min", 100);
            $("#calc_area").slider("option", "max", 1000);
            $("#calc_area").slider("option", "value", 100);
            $("#calc_area .custom-handle span").text(100);
        }
    });
    $("#calc_area_floor").change(function(){
        if(this.checked){
            $("#calc_area").slider("option", "min", 35);
            $("#calc_area").slider("option", "max", 200);
            $("#calc_area").slider("option", "value", 35);
            $("#calc_area .custom-handle span").text(35);
        }
    });

    initSingleSlider("#calc_distance", 0, 100);
    initSingleSlider("#calc_area_st", 35, 1000);
}
function initSingleSlider(selector, min, max){
    var handle = $( selector + " .custom-handle" );
    $(selector).slider({
        min: min,
        max: max,
        range: "min",
        create: function() {
            handle.find("span").text( $( this ).slider( "value" ) );
        },
        slide: function( event, ui ) {
            handle.find("span").text( ui.value );
        }
    });
}
function initMasks(){
    $("input[type='tel']").inputmask("+7 ( 9 9 9 ) 9 9 9 - 9 9 - 9 9");	
}
function initUSA(){
    if($(".use_area ").length){
        var useAreaTabs = new HTMLTabs({
            buttonsSelector: ".js-use_area-button",
            contentSelector: ".js-use_area-content",
            scrollToView: false,
            onAfterTabSwitch: function(tabId){
                $(".tab_sm--current").removeClass("tab_sm--current");
                var newTab = $(".use_area__switchers_sm-rest_item[data-tab=" + tabId + "]");
                newTab.addClass("tab_sm--current");
                $(".use_area__switchers_sm-current").html(newTab.html());
                $(".use_area__switchers_sm--active").removeClass("use_area__switchers_sm--active");
            },
        });
        $('.use_area__switchers_sm-current').click(function(){
            $(".use_area__switchers_sm").toggleClass("use_area__switchers_sm--active");
        });
        $('.use_area__switchers_sm-rest_item').click(function(){
            var tabId= $(this).data("tab");
            useAreaTabs.switchTab(tabId);
        });
        $(".use_area__item").each(function(index, elem){
            var buttonsClass = "js-usa-button" + index;
            var contentsClass = "js-usa-content" + index;
            $(elem).find(".js-usa-button").addClass(buttonsClass);
            $(elem).find(".js-usa-content").addClass(contentsClass);
            new HTMLTabs({
                buttonsSelector: "." + buttonsClass,
                contentSelector: "." + contentsClass,
                buttonActiveClass: "usa__switcher--active",
                contentActiveClass: "usa-content--active",
            });
        });
    } 
}
function initEquipmentTabs(){
    new HTMLTabs({
        buttonsSelector: ".js-equipment-button",
        contentSelector: ".js-equipment-content",
        scrollToView: false,
    });
}
function initVideoReviews(){
    new HTMLTabs({
        scrollToView: true,
        onAfterTabSwitch: function(){
            if($(".video_reviews__slider").hasClass("slick-initialized")){
                $(".video_reviews__slider").slick('refresh');
            }
        },
    });
    if(window.matchMedia("(max-width: 540px)").matches){
        $(".video_reviews__slider .styled_slider__nav_sm").addClass("styled_slider__nav_sm--active");
        $(".video_reviews__slider").each(function(index, elem){
            var node = $(elem);
            node.parent().find(".styled_slider__nav_sm").addClass("styled_slider__nav_sm--active");
            node.parent().find(".styled_slider__nav_sm-total").html(node.children().length);
            var slider = node.slick({
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: false,
                dots:false,
                useCSS: false,
                arrows: false,
            });
            node.parent().find(".styled_slider__nav_sm-button--left").click(function(){
                slider.slick('slickPrev');
            });
            node.parent().find(".styled_slider__nav_sm-button--right").click(function(){
                slider.slick('slickNext');
            });
            slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
                node.parent().find(".styled_slider__nav_sm-current").html(slick.currentSlide + 1);
            });
        });
    }
}
function initModals(){
    $(".js-modal_form_trigger").fancybox({
        touch: false,
        baseClass: "fancybox--form",
        beforeShow: function(){

        }
    });
}
function initStagesSlider(){
    if(window.matchMedia("(max-width: 540px)").matches){
        $(".block7 .styled_slider__nav_sm").addClass("styled_slider__nav_sm--active");
        var slider = $(".stages__items").slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            dots:false,
            useCSS: false,
            arrows: false,
        });
        $(".block7 .styled_slider__nav_sm-button--left").click(function(){
            slider.slick('slickPrev');
        });
        $(".block7 .styled_slider__nav_sm-button--right").click(function(){
            slider.slick('slickNext');
        });
        slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
            $(".block7 .styled_slider__nav_sm-big_count").html("0" + (1 + slick.currentSlide));
            if(slick.currentSlide + 1 === slick.slideCount){
                $(".block7 .swipe_note").addClass("swipe_note--reverse");
            }
            if(slick.currentSlide + 1 === 1){
                $(".block7 .swipe_note").removeClass("swipe_note--reverse");
            }
        });
    }
}
function initHeaderMenu(){
    var btns = document.querySelectorAll(".js--toggle_header_menu");

    for(var i = 0; i < btns.length; i++){
        var btn = btns[i];
        btn.addEventListener('click', function() {
            document.body.classList.toggle("header_mob_menu--active");
        });
    }
    
    var overlay = document.querySelector(".header_overlay");
    overlay.addEventListener('click', function(){
        document.body.classList.remove("header_mob_menu--active");
    });
}
function initReviews(){
    $(".styled_slider__nav_sm-total").html($('.reviews_slider').children().length);
    var slider = $('.reviews_slider').slick({
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        useCSS: false,
        autoplay: false,
        dots: true,
        responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 540,
                settings: {
                  slidesToShow: 1,
                  dots:false,
                }
              },
        ]
    });
    $(".reviews .styled_slider__nav_sm-button--left").click(function(){
        slider.slick('slickPrev');
    });
    $(".reviews .styled_slider__nav_sm-button--right").click(function(){
        slider.slick('slickNext');
    });
    slider.on('afterChange', function(event, slick, currentSlide, nextSlide){
        $(".reviews .styled_slider__nav_sm-current").html(slick.currentSlide + 1);
    });
}
